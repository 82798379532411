import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import BottomContent from '../components/bottomContent';
import ContactForm from '../components/contactForm';
import '../styles/contact.css';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const captchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={ captchaKey }>
      <div className="ContactPage">
        <Helmet>
          <title>Contact | Mason Ayres Creative</title>
          <meta name="description" content="So, you want to get in touch with me, huh? I'm flattered... Reach out!"/>
          <meta property="og:description" content="So, you want to get in touch with me, huh? I'm flattered... Reach out!"/>
        </Helmet>
        <div className="showuppls">
          <h1 className="ContactHeader">CONTACT MASON AYRES CREATIVE</h1>
          <div className="ContactDesc">
            <p>Reach out with your questions/inquiries about graphic design, photography & videography here!</p>
          </div>
        </div>
        <div className="formContainer">
          <ContactForm />
        </div>
        <div className="contactDetails">
          <h3>CONTACT US DIRECTLY</h3>
        <address>
        Email:<a href="mailto:macreative@masonayres.design">macr<span className="Obfuscate">BLEHBLEHBLEH 121 121  33  3 </span>eative@masona<span className="Obfuscate">BLEHBLEHBLEH 121 121  33  3 </span>yres.design</a>
        Phone Number:<a href="tel:4845773647">48<span className="Obfuscate">BLEHBLEHBLEH 121 121  33  3 </span>4-577<span className="Obfuscate">BLEHBLEHBLEH 121 121  33  3 </span>-3647</a>
        </address>  
        </div>
        <BottomContent />
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default Contact;
